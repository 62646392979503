
.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
  border: 1px solid #eaeaea;
}
.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
  border: 1px solid #eaeaea;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  font-size: 24px;
  line-height: 1.33;
  border-radius: 35px;
  border: 1px solid #eaeaea;
}


.btn.btn-primary.btn-circle:hover {
  background: #6589d8;
  border: 1px solid #6589d8;
}

.btn.btn-primary.btn-circle.active {
    background: #828282;
    border: 1px solid #8a8a8a;
}


.block-ui-overlay {
    background-color: #f7f7f7;
}
.block-ui-message {
    background-color: #7b7b7b;
    color: #f5f5f5;
    padding: 10px;
    border-radius: 3px;
    font-size: 14px;
}


[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}


@media (min-width: 768px)
{
    .col-md-2 {
        width: 20%;
    }
}



#screenshots .item {
  background: #ececec;
  padding: 10px 10px;
  margin: 5px;
  color: #FFF;
  border-radius: 3px;
  text-align: center;
}
.owl-theme .owl-nav {
  /*default owl-theme theme reset .disabled:hover links */
}
.owl-theme .owl-nav [class*='owl-'] {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.owl-theme .owl-nav [class*='owl-'].disabled:hover {
  background-color: #D6D6D6;
}
#screenshots.owl-theme {
  position: relative;
}
#screenshots.owl-theme .owl-next,
#screenshots.owl-theme .owl-prev {
  width: 22px;
  height: 40px;
  margin-top: -40px;
  position: absolute;
  top: 50%;
}
#screenshots.owl-theme .owl-prev {
  left: 10px;
}
#screenshots.owl-theme .owl-next {
  right: 10px;
}

.chosen-select {
  width: 100%; }

.chosen-select-deselect {
  width: 100%; }

.chosen-container {
  display: inline-block;
  font-size: 1rem;
  position: relative;
  vertical-align: middle; }
  .chosen-container .chosen-drop {
    background: #fff;
    border: 1px solid #ccc;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    margin-top: -1px;
    position: absolute;
    top: 100%;
    left: -9000px;
    z-index: 1060; }
  .chosen-container.chosen-with-drop .chosen-drop {
    left: 0;
    right: 0; }
  .chosen-container .chosen-results {
    color: #55595c;
    margin: 0 4px 4px 0;
    max-height: 240px;
    padding: 0 0 0 4px;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; }
    .chosen-container .chosen-results li {
      display: none;
      line-height: 1.5;
      list-style: none;
      margin: 0;
      padding: 5px 6px; }
      .chosen-container .chosen-results li em {
        background: #feffde;
        font-style: normal; }
      .chosen-container .chosen-results li.group-result {
        display: list-item;
        cursor: default;
        color: #999;
        font-weight: bold; }
      .chosen-container .chosen-results li.group-option {
        padding-left: 15px; }
      .chosen-container .chosen-results li.active-result {
        cursor: pointer;
        display: list-item; }
      .chosen-container .chosen-results li.highlighted {
        background-color: #0275d8;
        background-image: none;
        color: white; }
        .chosen-container .chosen-results li.highlighted em {
          background: transparent; }
      .chosen-container .chosen-results li.disabled-result {
        display: list-item;
        color: #818a91; }
    .chosen-container .chosen-results .no-results {
      background: #eceeef;
      display: list-item; }
  .chosen-container .chosen-results-scroll {
    background: white;
    margin: 0 4px;
    position: absolute;
    text-align: center;
    width: 321px;
    z-index: 1; }
    .chosen-container .chosen-results-scroll span {
      display: inline-block;
      height: 1.5;
      text-indent: -5000px;
      width: 9px; }
  .chosen-container .chosen-results-scroll-down {
    bottom: 0; }
    .chosen-container .chosen-results-scroll-down span {
      background: url("../img/chosen-sprite.png") no-repeat -4px -3px; }
  .chosen-container .chosen-results-scroll-up span {
    background: url("../img/chosen-sprite.png") no-repeat -22px -3px; }

.chosen-container-single .chosen-single {
  background-color: #fff;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  color: #55595c;
  display: block;
  height: 2.25rem;
  overflow: hidden;
  line-height: 2.25rem;
  padding: 0;
  position: relative;
  text-decoration: none;
  white-space: nowrap; }
  .chosen-container-single .chosen-single span {
    display: block;
    margin-right: 26px;
    padding-left: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .chosen-container-single .chosen-single abbr {
    background: url("../img/chosen-sprite.png") right top no-repeat;
    display: block;
    font-size: 1px;
    height: 10px;
    position: absolute;
    right: 26px;
    top: 14px;
    width: 12px; }
    .chosen-container-single .chosen-single abbr:hover {
      background-position: right -11px; }
  .chosen-container-single .chosen-single.chosen-disabled .chosen-single abbr:hover {
    background-position: right 2px; }
  .chosen-container-single .chosen-single div {
    display: block;
    height: 100%;
    position: absolute;
    top: 2px;
    right: 0;
    width: 18px; }
    .chosen-container-single .chosen-single div b {
      background: url("../img/chosen-sprite.png") no-repeat 0 7px;
      display: block;
      height: 100%;
      width: 100%; }
.chosen-container-single .chosen-default {
  color: #818a91; }
.chosen-container-single .chosen-search {
  margin: 0;
  padding: 3px 4px;
  position: relative;
  white-space: nowrap;
  z-index: 1000; }
  .chosen-container-single .chosen-search input[type="text"] {
    background: url("../img/chosen-sprite.png") no-repeat 100% -20px, #fff;
    border: 1px solid #ccc;
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    margin: 1px 0;
    padding: 4px 20px 4px 4px;
    width: 100%; }
.chosen-container-single .chosen-drop {
  margin-top: -1px;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box; }

.chosen-container-single-nosearch .chosen-search input[type="text"] {
  position: absolute;
  left: -9000px; }

.chosen-container-multi .chosen-choices {
  background-color: #fff;
  border: 1px solid #ccc;
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  cursor: text;
  height: auto !important;
  height: 1%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative; }
  .chosen-container-multi .chosen-choices li {
    float: left;
    list-style: none; }
  .chosen-container-multi .chosen-choices .search-field {
    margin: 0;
    padding: 0;
    white-space: nowrap; }
    .chosen-container-multi .chosen-choices .search-field input[type="text"] {
      background: transparent !important;
      border: 0 !important;
      color: #55595c;
      height: 2.25rem;
      margin: 0;
      padding: 0.375rem 0.75rem;
      outline: 0; }
    .chosen-container-multi .chosen-choices .search-field .default {
      color: #999; }
  .chosen-container-multi .chosen-choices .search-choice {
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    background-color: #eceeef;
    border: 1px solid #ccc;
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    background-image: linear-gradient(to bottom, white 0%, #eceeef 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFECEEEF', GradientType=0);
    color: #373a3c;
    cursor: default;
    line-height: 13px;
    margin: 6px 0 3px 5px;
    padding: 5px 20px 5px 5px;
    position: relative; }
    .chosen-container-multi .chosen-choices .search-choice .search-choice-close {
      background: url("../img/chosen-sprite.png") right top no-repeat;
      display: block;
      font-size: 1px;
      height: 10px;
      position: absolute;
      right: 4px;
      top: 7px;
      width: 12px;
      cursor: pointer; }
      .chosen-container-multi .chosen-choices .search-choice .search-choice-close:hover {
        background-position: right -11px; }
  .chosen-container-multi .chosen-choices .search-choice-focus {
    background: #d4d4d4; }
    .chosen-container-multi .chosen-choices .search-choice-focus .search-choice-close {
      background-position: right -11px; }
.chosen-container-multi .chosen-results {
  margin: 0 0 0 0;
  padding: 0; }
.chosen-container-multi .chosen-drop .result-selected {
  display: none; }

.chosen-container-active .chosen-single {
  border: 1px solid #66afe9; }
.chosen-container-active.chosen-with-drop .chosen-single {
  background-color: #fff;
  border: 1px solid #66afe9;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }
  .chosen-container-active.chosen-with-drop .chosen-single div {
    background: transparent;
    border-left: none; }
    .chosen-container-active.chosen-with-drop .chosen-single div b {
      background-position: -18px 7px; }
.chosen-container-active .chosen-choices {
  border: 1px solid #66afe9;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }
  .chosen-container-active .chosen-choices .search-field input[type="text"] {
    color: #111 !important; }
.chosen-container-active.chosen-with-drop .chosen-choices {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.chosen-disabled {
  cursor: default;
  opacity: 0.5 !important; }
  .chosen-disabled .chosen-single {
    cursor: default; }
  .chosen-disabled .chosen-choices .search-choice .search-choice-close {
    cursor: default; }

.chosen-rtl {
  text-align: right; }
  .chosen-rtl .chosen-single {
    padding: 0;
    overflow: visible; }
    .chosen-rtl .chosen-single span {
      margin-left: 26px;
      margin-right: 0;
      padding-right: 0.75rem;
      direction: rtl; }
    .chosen-rtl .chosen-single div {
      left: 7px;
      right: auto; }
    .chosen-rtl .chosen-single abbr {
      left: 26px;
      right: auto; }
  .chosen-rtl .chosen-choices .search-field input[type="text"] {
    direction: rtl; }
  .chosen-rtl .chosen-choices li {
    float: right; }
  .chosen-rtl .chosen-choices .search-choice {
    margin: 6px 5px 3px 0;
    padding: 5px 5px 5px 21px; }
    .chosen-rtl .chosen-choices .search-choice .search-choice-close {
      background-position: right top;
      left: 4px;
      right: auto; }
  .chosen-rtl.chosen-container-single .chosen-results {
    margin: 0 0 4px 4px;
    padding: 0 4px 0 0; }
  .chosen-rtl .chosen-results .group-option {
    padding-left: 0;
    padding-right: 15px; }
  .chosen-rtl.chosen-container-active.chosen-with-drop .chosen-single div {
    border-right: none; }
  .chosen-rtl .chosen-search input[type="text"] {
    background: url("../img/chosen-sprite.png") no-repeat -28px -20px, #fff;
    direction: rtl;
    padding: 4px 5px 4px 20px; }

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 2dppx) {
  .chosen-rtl .chosen-search input[type="text"],
  .chosen-container-single .chosen-single abbr,
  .chosen-container-single .chosen-single div b,
  .chosen-container-single .chosen-search input[type="text"],
  .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
  .chosen-container .chosen-results-scroll-down span,
  .chosen-container .chosen-results-scroll-up span {
    background-image: url("../img/chosen-sprite@2x.png") !important;
    background-size: 52px 37px !important;
    background-repeat: no-repeat !important; } }

.btn-file {
    position: relative;
    overflow: hidden;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

#img-upload{
    width: 100%;
}


.wrap-uploader .uploader {
      float: left;
      margin: 0 26px 20px 0;
      width: 45%;
    }

    .wrap-uploader .uploader .drop-zone {
      height: 100px;
      border: 1px dashed #999999;
      text-align: center;
      position: relative;
      font-size: 20px;
      color: #4d4d4d;
      overflow: hidden;
      border-radius: 10px;
    }

    .wrap-uploader .uploader .drop-zone .img-arrow {
      position: absolute;
      font-size: 150px;
      top: -68px;
      right: -26px;
      z-index: 0;
      -webkit-opacity: 0.1;
      /* Chrome,Safari4+ */
      -moz-opacity: 0.1;
      /* FF3.6+ */
      -ms-opacity: 0.1;
      -o-opacity: 0.1;
      opacity: 0.1;
      filter: progid:DXImageTransform.Microsoft.Alpha(opacity=10);
      filter: alpha(opacity=10);
    }

    .wrap-uploader .uploader .drop-zone .content {
      margin-top: 30px;
      font-size: 13px;
      z-index: 10;
    }

    .wrap-uploader .uploader .drop-zone .content .btn-browse {
      cursor: pointer;
      color: #ffffff;
      margin: auto;
      background-color: #4a7dba;
      font-size: 10px;
      padding: 2px 2px 2px 4px;
      border: 0;
      width: 80px;
      border-radius: 5px;
      margin-top: 4px;
      position: relative;
    }

    .wrap-uploader .uploader .drop-zone .content .btn-browse:hover {
      background-color: #4499DD;
    }

    .wrap-uploader .uploader .drop-zone .content .btn-browse .input-uploader {
      position: absolute;
      cursor: pointer;
      left: 0px;
      top: 0px;
      border: 1px solid;
      -webkit-opacity: 0;
      /* Chrome,Safari4+ */
      -moz-opacity: 0;
      /* FF3.6+ */
      -ms-opacity: 0;
      -o-opacity: 0;
      opacity: 0;
      filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
      filter: alpha(opacity=0);
      height: 20px;
      width: 80px;
    }
.screenshot-container
{

  .list-group-item {
    padding: 0.5rem .5rem
  }

  ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
    li {
      float: left;
      .image {
        width: 70px;
          overflow: hidden;
          height: 50px;
          margin: 2px 3px 0 0;
          position: relative;
          border: 1px solid #f1f1f1;
          img {
            position:relative;
            left: 50%;
            top: 50%;
            max-height: 76px;
          max-width: 100%;
          margin: auto;
            transform: translate(-50%, -50%);
          }
          .image-remove {
            position: absolute;
            top: 0;
            right: 0;
            border: 1px solid rgba(216, 43, 10, 0.42);
            font-size: 12px;
            width: 15px;
            height: 15px;
            opacity: 0.75;
            background-color: rgb(255, 255, 255);
            line-height: 0;
            text-align: center;
            cursor: pointer;
            i { margin-left: 2px; }
          }
      }
    }
  }
}

.text-center {
  text-align: center;
}


.readMoreFade {
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255,0,0,0)), color-stop(1, rgba(255,0,0,100)));
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 30px 0 30px 0;
}


[data-ratings] {
  width: 0;
  height: 0;
  opacity: 0;
  display: inline-block;
  white-space: nowrap;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  color: yellow;
  -webkit-transition: color .5s;
  transition: color .5s;
  vertical-align: top; }
  [data-ratings] svg {
    width: 20px;
    height: 20px;
    fill: #a4b10e; }
  [data-ratings] .ratings-empty,
  [data-ratings] .ratings-full {
    position: absolute;
    color: inherit;
    overflow: hidden;
    vertical-align: top; }


.rating {
  text-align: center;
}

.rating-num {
  color: #333333;
  font-size: 72px;
  font-weight: 100;
  line-height: 1em;
}

.rating-stars {
  font-size: 20px;
  color: #E3E3E3;
  margin-bottom: .5em;
}

.rating-stars .active {
  color: #737373;
}

.rating-users {
  font-size: 14px;
}

.histo {
  font-size: 13px;
}

.histo-star {
  float: left;
  padding: 3px;
}

.histo-rate {
  width: 100%;
  display: block;
  clear: both;
}

.bar-block {
  margin-left: 5px;
  color: black;
  display: block;
  float: left;
  width: 75%;
  position: relative;
}

.bar {
  padding: 4px;
  display: block;
}

#bar-five {
  width: 0;
  background-color: #9FC05A;
}

#bar-four {
  width: 0;
  background-color: #ADD633;
}

#bar-three {
  width: 0;
  background-color: #FFD834;
}

#bar-two {
  width: 0;
  background-color: #FFB234;
}

#bar-one {
  width: 0;
  background-color: #FF8B5A;
}